import './App.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal';
import {Buffer} from 'buffer'
import React from 'react';
import axios from 'axios';
import ReactDOM from "react-dom";
import { Alert } from 'react-bootstrap';

function GetPhiUrl() {
  let phiUrl = process.env.REACT_APP_PHI_URL;

  if (phiUrl === undefined) {
    phiUrl = "https://fgen.feele.exeter.ac.uk/phi/";
  }
  
  return phiUrl;
}

const App = () => {
  let [responseData, setResponseData] = React.useState('');
  let [userData, setUserData] = React.useState('');
  let [responseErrorMessage, setResponseErrorMessage] = React.useState('');
  const [loadingUserDetails, setLoadingUserDetails] = React.useState(false);
  const [show, setShow] = React.useState("dataEntry");

  // Get the data from the encoded URL param
  const queryParams = new URLSearchParams(window.location.search);
  const epd = queryParams.get('epd');
  var payment = 0.0;
  var computername = "";  
  if (epd) {
    const decoded_epd = JSON.parse(Buffer.from(epd, 'base64'));
    computername = decoded_epd.computername;
    payment = decoded_epd.payment.toFixed(2);
    console.log("Computername : " + computername + ", payment: " + payment + ", loading: " + loadingUserDetails);
  } else {
    if (show !== "errorNotice") {
      setShow("errorNotice");
    }
  }

  const finishedEvent = (value) => {
    // Hides the main page, shows the Thank you one
    setShow(value)
  }

  const hideSubmitButton = () => {
    console.log("About to hide button");
    setLoadingUserDetails(true);
  }

  const unhideSubmitButton = () => {
    console.log("About to unhide button");
    setLoadingUserDetails(false);
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    // Disable the Submit button until we have the users details
    hideSubmitButton();

    let email = e.target.formBasicEmail.value;
    // From date is from first thing today
    let daybreak = new Date();
    daybreak.setHours(0);
    daybreak.setMinutes(0);
    daybreak.setSeconds(0);
    let fromTime = daybreak.getTime();
    let toTime = Date.now();

    console.log("Looking for user: " + email + ", between (" + fromTime + "," + toTime + ")");

    // Get the users sessions from hroot
    axios({
      "method": "GET",
      "url": GetPhiUrl() + "getSessions",
      "headers": {
        "content-type": "application/json"
      }, "params": {
        "language_code": "en",
        "from": fromTime,
        "to": toTime,
        "emailAddress": email
      }
    })
      .then((response) => {
        ReactDOM.unstable_batchedUpdates(() => {

          if (response.data.error === 0) {
            console.log("Found user: " + email); // + ", data: " + JSON.stringify(response.data));
            setResponseData(response.data);
            setUserData(response.data.firstName + " " + response.data.lastName)
            setResponseErrorMessage(undefined);
            setLoadingUserDetails(false);
  
          } else if (response.data.error === 20) {
            console.log("User not found: " + response.data.message);
            setResponseErrorMessage(response.data.message);
            setUserData(undefined)
            setResponseData(undefined)
            setLoadingUserDetails(false);
  
          } else if (response.data.error === 21) {
            console.log("No sessions for this user: " + response.data.message);
            setResponseErrorMessage(response.data.message);
            setUserData(response.data.firstName + " " + response.data.lastName)
            setResponseData(undefined)
            setLoadingUserDetails(false);
  
          } else {
            console.log("Unexpected error: " + response.data.message);
            setResponseErrorMessage(response.data.message);
            setUserData(undefined)
            setResponseData(undefined)
            setLoadingUserDetails(false);
          }
        });
      })
      .catch((error) => {
        console.log(error)
        console.log("Error: Unexpected error: " + error);
        setResponseErrorMessage(error.toString());
        setUserData(undefined)
        setResponseData(undefined)
        setLoadingUserDetails(false);
    })
  }

  return (
    <div>
      <div className={show === "dataEntry" ? "App d-flex flex-column align-items-center" : "hidden"}>
        <Image src="header-new.png" fluid />
        <p>We need <b>some information to pay you</b>. This information will not be associated
          with the decisions that you have made today, or linked with the experimental data that
          has been recorded. It will not be used for any purpose other than issuing payment for
          this experiment.</p>
        <p><strong><u>Payment: £{payment}</u></strong></p>
        <Form onSubmit={handleOnSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail" >
            <Form.Label>Please enter your University of Exeter email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" autoComplete="off"/>
            <Form.Text className="text-muted">
              <div>We'll use this to look up your account<br /> in hroot and find active sessions
              </div>
            </Form.Text>
          </Form.Group>

          <Button type='submit' variant='primary' disabled={loadingUserDetails}>
            {loadingUserDetails ? "Loading..." : "Submit"}
          </Button>
          <User user={userData} />
          <Session data={responseData} finish={finishedEvent} payment={payment} computername={computername} unhideSubmitButton={unhideSubmitButton}/>
          <ErrorMessage message={responseErrorMessage} />
        </Form>
      </div>
      <div className={show === "thankYou" ? "App d-flex flex-column align-items-center align-text-left" : "hidden"}>
        <Image src="header-new.png" fluid />
        <p>Thank you for your time today. Your payment details have been submitted, and you will 
          shortly receive a confirmaton email.</p>
        <p>We pay you through <strong>Wise.com</strong>, this is super-fast and safe, and generally you will
          receive an email from Wise soon after the experiment. The email will look something like this 
          (John Doe will obviously be replaced with the name of paeron making the payment - their name will
          be in the confirmaton email):</p>
        
        <Image src="wisemail.png" fluid />

        <p>If you already have a Wise account, payment will automatically be made into your bank account</p>
        <p>If you don't have a Wise.com account, the email will contain a link that takes you to the Wise account 
          creation page.</p>
        <p>When you create a Wise account, you will need to enter your bank details. Then your earnings will be paid 
          directly into your bank account.</p>
        <p><strong>Note:</strong> You may be charged a fee if your account is not in GBP</p>
      </div>
      <div className={show === "errorNotice" ? "App d-flex flex-column align-items-center" : "hidden"}>
        <Image src="header-new.png" fluid />
        <p>You have arrived at this page in error.</p>
      </div>
    </div >
  );
}

const Session = (props) => {
  let rowData = undefined;
  if (props.data) {
    rowData = props.data.sessions;
  } 
  // else {
  //   console.log("No session data, re-enabling the Submit button...");
  //   // Need to re-enable the Submit button here...
  //   props.unhideSubmitButton();
  // }

  if (rowData) {
    return (
      <div className={rowData ? 'session-list' : 'hidden'} >
        <div className="text-muted">Please select the session you have just completed below</div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rowData && rowData.map(session => {
              var fromDate = new Date(session.from)
              return <tr key={session.sessionId}>
                <td>{fromDate.toLocaleDateString()}</td>
                <td>{fromDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
                {(session.payment == null || session.payment < 0.001) &&
                  <td><SessionConfirmationDlg
                    data={session}
                    name={props.data.firstName + " " + props.data.lastName}
                    email={props.data.email}
                    finish={props.finish}
                    payment={props.payment}
                    computername={props.computername}/></td>
                }
                {session.payment != null &&
                  <td>£{session.payment.toFixed(2)}</td>
                }
              </tr>
            })}
          </tbody>
        </Table>
      </div>
    )
  } else {
    return (<div></div>)
  }
}

const ErrorMessage = (props) => {
  let message = props.message;
  if (message) {
    console.log("Message: " + message)
  }

  return (<p className={message ? undefined : 'hidden' } style={{color: 'red'}}><strong>{message}</strong></p>)
}

const User = (props) => {
  let name = props.user;
  console.log("User: " + name)

  return (<p className={name ? undefined : 'hidden'}>User: <strong>{name}</strong></p>)
}

const SessionConfirmationDlg = (props) => {
  const [show, setShow] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const [sentConfirmation, setSentConfirmation] = React.useState(false);

  const handleClose = () => {
    setShow(false); 
    setAlert(null);
  }
  const handleShow = () => {
    setShow(true); 
    setAlert(null);
  }

  const updateSession = () => {
    setSentConfirmation(true)
    
    // Update the participation in hroot
    var participationId = props.data.sessionId;
    axios({
      "method": "POST",
      "url": GetPhiUrl() + "updateAttendance",
      "headers": {
        "content-type": "application/text"
      }, "params": {
        "participationId": participationId,
        "payment": props.payment,
        "startTime": props.data.from,
        "name": props.name,
        "email": props.email,
        "computername": props.computername,
      }
    })
      .then((response) => {
        if (response.data.error === 0) {
          console.log("Updated session details");
          // Hide the dialog
          setShow(false);
          // Callback method, set the state on the main app to hide the selection pages and show a Thank You page 
          props.finish("thankYou")
        } else {
          console.log("Failed to update session. Error: " + response.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
        setAlert(error.message)
      })
  }
  var fromDate = new Date(props.data.from);

  return (

    <>
      <Button variant="primary" onClick={handleShow}>Select</Button>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Experiment Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Table striped bordered >
            <tbody>
              <tr>
                <td>Name</td>
                <td>{props.name}</td>
              </tr>
              <tr>
                <td>Experiment Date</td>
                <td>{fromDate.toLocaleDateString()}</td>
              </tr>
              <tr>
                <td>Experiment Time</td>
                <td>{fromDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
              </tr>
              <tr>
                <td>Payment</td>
                <td>£{props.payment}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Alert key="A1" variant="danger" show={alert}>
            Could not update your record. Error message: {alert}
          </Alert>
          <Button variant="secondary" onClick={handleClose} disabled={sentConfirmation}>
            Cancel
          </Button>
          <Button variant="primary" onClick={updateSession} disabled={sentConfirmation}>
            {sentConfirmation ? "Please wait..." : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default App;
